import { HTTP } from '@/App/Utils/axios'
import { DeviceTripItem } from '@/App/Services/interfaces/flespi.interface'

export class FlespiService {
  static async fetchDevices(): Promise<Device[]> {
    try {
      const { data } = await HTTP.get('/flespi/devices')
      return data
    } catch (e) {
      throw e
    }
  }

  static async createDevice(device: CreateDeviceRequest): Promise<Device> {
    try {
      const { data } = await HTTP.post('/flespi/devices', device)

      return data
    } catch (e) {
      throw e
    }
  }

  static async updateDevice(deviceId: number, device: CreateDeviceRequest): Promise<Device> {
    try {
      const { data } = await HTTP.put(`/flespi/devices/${deviceId}`, device)

      return data
    } catch (e) {
      throw e
    }
  }

  static async deleteDevice(deviceId: number): Promise<void> {
    try {
      await HTTP.delete(`/flespi/devices/${deviceId}`)
    } catch (e) {
      throw e
    }
  }

  static async fetchDeviceSensors(deviceId: string): Promise<string[]> {
    try {
      const { data } = await HTTP.get(`/flespi/devices/${deviceId}/sensors`)

      return data
    } catch (e) {
      throw e
    }
  }

  static async fetchDeviceSettings(deviceId: string): Promise<string[]> {
    try {
      const { data } = await HTTP.get(`/flespi/devices/${deviceId}/settings`)

      return data
    } catch (e) {
      throw e
    }
  }

  static async fetchDeviceMessagesHistory({ deviceId, fields, ...payload }: MessageHistoryRequest): Promise<MessageHistoryResponse[]> {
    try {
      const { data } = await HTTP.get(`/flespi/devices/${deviceId}`, {
        params: {
          fields: fields.join(','),
          ...payload
        }
      })

      return data
    } catch (e) {
      throw e
    }
  }

  static async fetchDeviceTelemetryMessages({ deviceId, ...payload }: DeviceTelemetryMessagesRequest): Promise<MessageHistoryResponse[]> {
    try {
      const params: { from: number, to: number, fields?: string } = { from: payload.from, to: payload.to }

      if (payload.fields) {
        params.fields = payload.fields.join('')
      }

      const { data } = await HTTP.get(`/flespi/devices/${deviceId}/telemetry`, {
        params: {
          ...params
        }
      })

      return data
    } catch (e) {
      throw e
    }
  }

  static async updateDeviceSetting(deviceId: number, payload: FlespiUpdateSettings): Promise<void> {
    try {
      const { data } = await HTTP.put(`/flespi/devices/${deviceId}/settings`, payload)

      return data
    } catch (e) {
      throw e
    }
  }

  static async fetchCalculators(): Promise<Calculator[]> {
    try {
      const { data } = await HTTP.get('/flespi/calculators')

      return data
    } catch (e) {
      throw e
    }
  }

  static async getDeviceCalculations(deviceId: number, calculatorId: number, options?: CalculatorOptions): Promise<DeviceCalculationResponse[]> {
    try {
      const { data } = await HTTP.get(`flespi/devices/${deviceId}/calculate/${calculatorId}`, { params: options })

      return data
    } catch (e) {
      throw e
    }
  }

  static async fetchDeviceTrips(deviceId: number, options: CalculatorOptions): Promise<DeviceTripItem[]> {
    try {
      const { data } = await HTTP.get(`flespi/devices/${deviceId}/trips`, { params: options })

      return data
    } catch (e) {
      throw e
    }
  }

  static async fetchDeviceCommands(deviceId: number): Promise<string[]> {
    try {
      const { data } = await HTTP.get(`flespi/devices/${deviceId}/commands`)

      return data
    } catch (e) {
      throw e
    }
  }

  static async sendDeviceCommand(deviceId: number, command: DeviceCommandRequest): Promise<DeviceCommandResponse> {
    try {
      const { data } = await HTTP.post(`flespi/devices/${deviceId}/commands`, command)

      return data
    } catch (e) {
      throw e
    }
  }

  static async fetchDeviceTypes(): Promise<DeviceTypeItem[]> {
    try {
      const { data } = await HTTP.get('flespi/devices/types')

      return data
    } catch (e) {
      throw e
    }
  }
}

interface DeviceTypeItem {
  id: number;
  protocol_id: number;
  protocol_name: string;
  title: string;
}

export interface DeviceCommandResponse {
  timestamp: number
  response: string
  id: number
  position: number
  name: string
  device_id: number
  executed: true
  properties: {
    payload?: string;
  }
}

export interface Device {
  id: number
  name: string
  messages_ttl: number
  last_active: number;
  device_type_id: number
  cid: number
  configuration: {
    ident: string
    phone?: string
    settings_polling: string
  }
}

export interface CreateDeviceRequest {
  configuration: {
    ident: string
    phone?: string
  }
  name: string
  device_type_id: number
  messages_ttl: number
}

interface FlespiUpdateSettings {
  settingName: string
  payload: any
}

interface MessageHistoryRequest {
  deviceId: number
  fields: string[]
  from: number
  to: number
  mathExpression?: string
  mathExpressionField?: string
  widgetId: string;
  dashboardId?: string
}

interface DeviceTelemetryMessagesRequest {
  deviceId: number
  fields?: string[]
  from: number
  to: number
}

interface MessageHistoryResponse {
  [key: string]: number | string
}

export interface Calculator {
  id: number
  name: string
}

interface DeviceCalculationResponse {
  begin: number
  end: number
  duration: number
  [key: string]: number
}

interface CalculatorOptions {
  from: number
  to: number
}

export interface DeviceCommandRequest {
  name: string
  payload?: string
}
